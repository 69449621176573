<template lang="html">
<two :masonry-selector="'.project-container'" ref="two">
  <template v-slot:left>
    <project-nav @chosen="$root.moveToProject" ref="nav"></project-nav>
    <div class="project-text-container" :class="{ hide: $root.hidden }">
      <h1 class="project-title">
        {{ project.title }}
      </h1>

      <div class="project-description">
        <div class="inner" >
          <ul class="project-meta">
            <li v-for="meta in projectMeta" :key="meta.key">
              <b>{{ meta.key }}</b> : {{ meta.value }}
            </li>
            <div class="" v-html="project.description">

            </div>
          </ul>
        </div>
      </div>
    </div>
  </template>

  <template v-slot:right>
    <div class="project-container">
      <div class="grid-sizer"></div>
      <div v-for="image in images" :key="image.filename" class="grid-item">
        <div class="inner">
          <img :srcset="image.srcset" :alt="image.name"
                :sizes="`(max-width: 600px) 100vw, (max-width: 960px) 66vw, ${image.vw}vw`"
                :data-filename="image.filename"
                @load="layoutImages">
        </div>
      </div>
      <div class="grid-item full-width other-project-separator">
        <div class="inner">
          <b>Other Projects</b>
        </div>
      </div>
      <project-thumbnail v-for="project in $root.otherProjects" :key="project.slug"
                          :project="project"
                          :small="true"
                          @img-load="layoutImages"
                          @moving="movingToProject">
      </project-thumbnail>
    </div>
  </template>
</two>
</template>

<script>
import Masonry from 'masonry-layout';
import Two from '@/components/Two.vue';
import ProjectNav from '@/components/ProjectNav.vue';
import ProjectThumbnail from '@/components/ProjectThumbnail.vue';

export default {
  components: {
    Two,
    ProjectNav,
    ProjectThumbnail,
  },
  props: {
    projectSlug: String,
  },
  data() {
    return {
      mason: null,
    };
  },
  computed: {
    project() {
      return __data__.projects[this.projectSlug];
    },
    images() {
      return this.getImages(this.project);
    },
    projectMeta() {
      const { meta } = this.project;
      if (!meta || meta.lenght === 0) {
        return [];
      }
      return meta.map((e) => (
        {
          key: Object.keys(e)[0],
          value: Object.values(e)[0],
        }
      ));
    },
  },
  watch: {
    project() {
      this.$root.background = this.project.colour;
    },
  },
  methods: {
    layoutImages() {
      const container = document.querySelector('.project-container');
      container.querySelectorAll('img').forEach((img) => {
        const gridItem = img.parentElement.parentElement;
        const { filename } = img.dataset;
        if (filename) {
          if (filename.search(/_full/i) >= 0) {
            gridItem.classList.add('full-width');
          }
        }
      });

      this.mason = new Masonry(container, {
        itemSelector: '.grid-item',
        columnWidth: '.grid-sizer',
        percentPosition: true,
        transitionDuration: 0,
      });
    },
    movingToProject(project) {
      this.$refs.nav.slide();
    },
  },
  created() {
    this.$root.background = this.project.colour;
    this.$root.currentProject = this.projectSlug;
  },
  mounted() {
    setTimeout(() => {
      this.$root.hidden = false;
    }, 1000);

  },
};
</script>

<style lang="scss">
@import "@/styles/variables.scss";

.project-container {
  margin: $photo-padding;
  transition: opacity $open-fade-time;

}

.project-text-container {
  transition: opacity $open-fade-time;
  margin-left: 3vw;
  width: 80%;
  padding-top: 42vh;
}

.project-title {
  font-size: 2.5rem;
}

.project-text {

}

.project-description {
  // position: absolute;
  // top: 60vh;
  overflow: hidden;
  height: calc(50vh - 100px);
  width: 100%;
  // background: #f00;

  .inner {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding-right: 17px;
    box-sizing: content-box;
  }

  h1 {
    margin-top: 0px;
    margin: 10px 0px;
    font-size: 1.5rem;
    font-weight: normal;
  }
  h2 {
    font-weight: normal;
    font-size: 1rem;
    text-decoration: underline;
  }

  ul {
    margin: 20px 0px;
  }

  p {
    margin: 10px 0px;
  }
}

@media only screen and (max-width: 760px) {
  .project-text-container {
    margin: 0;
    padding: $photo-padding * 2;
    padding-top: 60px;
  }

  .project-title, .project-description {
    position: unset;
    height: unset;
  }
  .project-text {
    margin-left: 10px;
    padding-top: 50px
  }
}

.other-project-separator {
  margin-top: 50px;
}

</style>
