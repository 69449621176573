import Vue from 'vue';
import Router from 'vue-router';
import Projects from './views/Projects.vue';
import Project from './views/Project.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/projects',
      name: 'projects',
      component: Projects,
      alias: '/'
    },
    {
      path: '/project/:projectSlug',
      name: 'project',
      component: Project,
      props: true,
    },
    {
      path: '/about',
      name: 'about',
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import(/* webpackChunkName: "contact" */ './views/Contact.vue'),
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
});

router.beforeEach(function (to, from, next) {
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
    next();
});

export default router;
