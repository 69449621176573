import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBpVAzSv63ZmIvQ24_fp_BiRP0gPQhBSUQ',
  },
});
/* eslint quote-props: "off" */
const makeSrcset = (project, filename) => [{
  'folder': 's',
  'width': 300,
},
{
  'folder': 'm',
  'width': 640,
},
{
  'folder': 'l',
  'width': 1280,
},
{
  'folder': 'xl',
  'width': 2000,
}].map(size => `/images/projects/${project.slug}/${size.folder}/${filename.replace(/ /g, '%20')} ${size.width}w`)
  .join(', ');

Vue.mixin({
  methods: {
    makeTitle: str => str.charAt(0).toUpperCase() + str.slice(1),
    getThumbnailImageSrcSet: project => makeSrcset(project, project.main_image),
    getImages: project => project.images.map(filename => ({
      filename,
      srcset: makeSrcset(project, filename),
      vw: filename.search(/_full/i) >= 0 ? 66 : 33,
    })).sort((a, b) => a.filename < b.filename),
  },
});

const projects = Object.values(__data__.projects)
  .sort((a, b) => {
    if (a.directory_name < b.directory_name) {
      return -1;
    } else if (a.directory_name > b.directory_name) {
      return 1;
    } else {
      return 0;
    }
  });

const colours = ['red', 'green', 'blue', 'black'];
const randomColour = colours[Math.floor(Math.random() * colours.length)];

new Vue({
  router,
  render: h => h(App),
  data() {
    return {
      projects,
      currentProject: null,
      background: '',
      initialBackground: randomColour,
      hideNav: false,
      hidden: false,
    };
  },
  computed: {
    currentProjectIndex() {
      for (let i = 0; i < this.projects.length; i++) {
        if (this.projects[i].slug === this.currentProject) {
          return i;
        }
      }
    },
    otherProjects() {
      const projects = this.projects.filter(p => p.slug !== this.currentProject);
      const shuffled = projects.sort(() => 0.5 - Math.random() * this.currentProjectIndex);
      return shuffled.slice(0, 4);
    },
  },
  methods: {
    moveToProject(projectSlug) {
      this.hidden = true;
      this.currentProject = projectSlug;

      setTimeout(() => {
        this.$router.push({ name: 'project', params: { projectSlug } });
        this.hidden = false;
      }, 1000);
    },
  },
}).$mount('#app');
