<template lang="html">
<div @click="triggerAnimation">
  <div v-if="doIntroAnimation"
        class="logo-background-backing"
        :class="{ transparent: backgroundLaid }">
  </div>
  <div v-if="doIntroAnimation"
        class="logo-background"
        :class="{
          transparent: !$root.hideNav || !reveal,
          }"
        :style="{ backgroundImage: randomBackgroundImage }">
  </div>
  <div v-if="showLogo" class="logo-container"
      :class="{
        ended: transitionFinished,
        sliding: logoAtLeft,
        animate: doIntroAnimation,
        reveal: reveal,  }">
    <router-link :to="{ path: '/' }">
      <svg version="1.1" id="logos"
            :class="{ ended: logoAtLeft  }"
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 732.9 54.5" data- xml:space="preserve">
        <g id="bits">
          <rect id="l-end" x="73.2" y="49" class="st1" width="32.2" height="4.6"/>
          <rect id="l-start" :class="{ animate: doIntroAnimation }"
                x="673.2" y="49" class="st1" width="32.2" height="4.6"/>
          <g id="h">
            <rect y="1.1" class="st2" width="5.2" height="52.5"/>
            <rect x="36.6" y="1" class="st2" width="5.2" height="52.5"/>
          </g>
        </g>
        <g v-if="doIntroAnimation" id="text" :class="{ transparent: !$root.hideNav }">
          <path id="holloway" class="st0" d="M501,1.1h-5.8l-16.3,27.6L462.5,1.1h-6.1l19.7,32.7v19.8h5.2V33.8L501,1.1z M411.3,34.1
            l11.2-27.5l11.1,27.5H411.3z M441.4,53.6h5.6L425.5,1.1h-5.8l-21.6,52.5h5.3l6.1-15h25.9L441.4,53.6z M363.2,53.6h5.5l16.4-52.5h-5
            l-14,45.5L353.3,1.1h-5.6L335,46.8L321,1.1h-5.3L332,53.6h5.5l12.8-45.5L363.2,53.6z M272.1,49.8c-11.3,0-20.3-9.3-20.3-22.7
            s9-22.4,20.3-22.4s20.3,9,20.3,22.4C292.4,40.6,283.5,49.8,272.1,49.8 M272.1,54.5c14.2,0,25.9-11,25.9-27.5S286.2,0,272.1,0
            s-25.8,10.7-25.8,27.1C246.3,43.6,257.8,54.5,272.1,54.5 M197.6,53.6h32.2V49h-27V1.1h-5.2V53.6z M143.1,53.6h32.2V49h-27V1.1h-5.2
            V53.6z M92.5,49.8c-11.3,0-20.3-9.3-20.3-22.7s9-22.4,20.3-22.4s20.3,9,20.3,22.4C112.9,40.6,103.9,49.8,92.5,49.8 M92.5,54.5
            c14.2,0,25.9-11,25.9-27.5S106.6,0,92.5,0S66.7,10.7,66.7,27.1C66.7,43.7,78.3,54.5,92.5,54.5 M0,53.6h5.2V28.8h31.3v24.8h5.3V1.1
            h-5.3v23.1H5.2V1.1H0V53.6z"/>
          <path id="li" class="st0" d="M673.2,1.1v52.5h32.2V49h-27V1.1H673.2z M727.7,53.6h5.2V1.1h-5.2V53.6z"/>
        </g>
      </svg>
    </router-link>
  </div>
  <div class="column left" :class="$root.background">
    <slot></slot>
    <div v-if="showLogo" class="company-info" :class="{ transparent: $root.hideNav }">
      <p class="title slick">Architecture + Interiors | London + Global</p>
      <p>We are a design consultancy with an established reputation for
        providing innovative architecture and interior design direction
        to a growing network of international clients across the retail,
        hospitality and private residential sectors.</p>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      doIntroAnimation: false,
      monogramWidth: 0,
      lEndX: 0,
      showLogo: true,
      logoAtLeft: false,
      transitionFinished: false,
      reveal: false,
      backgroundLaid: false,
    };
  },
  computed: {
    randomBackgroundImage() {
      const img = __data__.backgrounds.sort(() => 0.5 - Math.random())[0];
      return `url("/images/backgrounds/${img}")`;
    },
  },
  methods: {
    triggerAnimation() {
      if (this.doIntroAnimation) {
        this.startLogoTransition();
        setTimeout(this.moveL, 1000);
        setTimeout(() => {
          this.doIntroAnimation = false;
          this.finishLogoTransition();
          this.$router.replace({ name: 'projects' });
        }, 2000);
      }
    },
    startLogoTransition() {
      this.$root.hideNav = false;
      document.querySelector('#logos').style.transition = 'margin-left 1s';
    },
    moveL() {
      document.querySelector('#l-start').style.x = this.lEndX;
      this.logoAtLeft = true;
    },
    finishLogoTransition() {
      const logos = document.querySelector('#logos');
      const viewBoxWidth = logos.viewBox.baseVal.width;
      const actualWidth = logos.getBoundingClientRect().width;
      const ratio = this.monogramWidth / viewBoxWidth;
      logos.style.width = `${actualWidth * ratio}px`;
      logos.viewBox.baseVal.width = this.monogramWidth;
      this.transitionFinished = true;
    },
    skipLogoTransition() {
      this.$root.hideNav = false;
      document.querySelector('#logos').style.transition = '';
      this.moveL();
      this.finishLogoTransition();
    },
  },
  created() {
    this.showLogo = this.$route.name !== 'project';
    this.doIntroAnimation = this.showLogo && this.$route.path === '/';
  },
  mounted() {
    if (this.showLogo) {
      const lEnd = document.querySelector('#l-end');
      this.lEndX = lEnd.x.baseVal.value;
      this.monogramWidth = this.lEndX + lEnd.width.baseVal.value;
      lEnd.style.display = 'none';
    }

    if (this.doIntroAnimation) {
      this.$root.hideNav = true;
      setTimeout(() => {
        this.reveal = true;
        setTimeout(() => {
          this.backgroundLaid = true;
        }, 2000);
      }, 500);
    } else if (this.showLogo) {
      this.skipLogoTransition();
    }

  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

div.column.left {
  position: fixed;
  width: 33%;
  height: 100vh;
  color: #fff;
  transition: background-color $open-fade-time;
}

@media only screen and (max-width: 760px) {
  div.column.left {
    position: relative;
    top: 0;
    z-index: 3;
    width: 100%;
    height: unset;
  }
}

.logo-container {
  // display: flex;
  padding-left: $left-pad;
  padding-top: 45vh;
  z-index: 5;
  position: fixed;
  width: 100vw;
  transition: opacity 2s;
  opacity: 0;

  &.ended {
    width: unset;
    opacity: 1;
    transition: opacity 0s;

  }

  svg {
    display: block;
    margin-left: calc(50% - 26vw);
    width: 50vw;


    &.ended {
      margin-left: 0px;
    }

    rect {
      &.animate {
        transition: x 1s, opacity $logo-fade-time;
      }
      // transition: opacity $logo-fade-time;
      fill: #fff;
    }

    path {
      fill: #fff;
      transition: opacity $logo-fade-time;
    }
  }
}

.company-info {
  position: fixed;
  top: 0;
  padding-left: $left-pad;
  padding-top: 53vh;
  transition: opacity $logo-fade-time;
  margin: 30px 0px;
  max-width: 25%;
  p {
    margin: 10px 0px;
  }
  .title {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 760px) {
  .company-info {
    display: none;
  }
}

.transparent {
  opacity: 0;
}


@media only screen and (max-width: 760px) {
  .logo-container {
    padding-top: 0px;
    padding-left: 0px;
    bottom: 50%;
    left: 0;
    opacity: 0;

    &.sliding {
      // padding-top: 20px;
      position: fixed;
      z-index: 5;
      // right: 27px;
      bottom: 14px;
      // padding: 0;
      left: $photo-padding;

    }
    &.animate {
      transition: padding-top 1s, bottom 1s, left 1s;
    }
    svg {
      // margin-left: calc(50% - 40vw);
      // width: 50vw;
      rect {
        transition: fill 2s;
      }
      &.ended {
        margin-left: 10px;
      }
    }

    &.sliding {
      svg {
        rect {
          fill: $almost-black;
        }
      }
    }
  }
}

#text {
  transition: opacity $logo-fade-time;
}

.logo-background-backing {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $black;
  transition: opacity 1s;
}


.logo-background {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  // width: 100%;
  // height: 100%;
  transition: opacity $logo-fade-time;
  background: $black no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  background-attachment: scroll;
}

.reveal {
  opacity: 1;
}

</style>
