<template>
<div id="app" :class="$root.background">
  <router-view/>
  <nav :class="{ [$root.background]: true, transparent: $root.hideNav }">
    <ul>
      <li v-for="page in nav" :key="page">
        <router-link :to="{ name: page }">
          {{ makeTitle(page) }}
        </router-link>
      </li>
    </ul>
  </nav>
</div>
</template>

<script>
import "./styles/main.scss"

export default {
  data() {
    return {
      nav: [
        'projects',
        'about',
        'contact',
      ],
    };
  },
  created() {
  },
  beforeMount() {
    this.$root.hideNav = this.$route.path === '/';
  },
};
</script>

<style lang="scss">
@import "@/styles/variables.scss";
//@import "@/styles/main.scss";


#app  {
  transition: background-color $open-fade-time, opacity 2s;
}

@media only screen and (max-width: 760px) {

}

nav {
  position: fixed;
  padding-left: $left-pad;
  transition: opacity $logo-fade-time, background-color $open-fade-time;
  bottom: 0;
  padding-bottom: $left-pad;
  padding-top: 10px;
  z-index: 3;
  font-size: 1.25rem;
  color: #eee;
  width: 26%;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: inline;
      margin-right: 10px;
    }
  }
  a {
    // color: #111;
    text-decoration: none;
  }
}
@media only screen and (max-width: 760px) {
  nav {
    width: calc(100% - #{$left-pad});
    font-size: 1.2rem;
    background-color: $grey !important;
    color: $almost-black;
    padding-left: $photo-padding * 2;
    padding-bottom: 0px;
    height: 32px;
    padding-top: 12px;

    ul {
      margin-left: 55px;
    }
  }
}
</style>
