<template lang="html">
<div class="lines" :class="$root.background"
      @mouseenter="mouseOver = true" @mouseleave="mouseOver = false">
  <div class="inner">
    <div v-for="project, index in $root.projects" :key="project.slug"
          class="line-container"
          :class="{
            down: !mouseOver && project.slug === $root.currentProject,
            hide: index > end || index < start,
          }"
          @click="chooseProject(project)">
      <div class="line"></div>
      <div class="text">{{ project.title }}</div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      mouseOver: false,
      reach: 2,
    };
  },
  computed: {
    start() {
      const { currentProjectIndex } = this.$root;
      return Math.max(0, currentProjectIndex - this.reach);
      const end = Math.min(this.$root.projects.length, currentProjectIndex + this.reach + 1);
    },
    end() {
      const { currentProjectIndex } = this.$root;
      return Math.min(this.$root.projects.length, currentProjectIndex + this.reach);
    },
  },
  methods: {
    chooseProject(project) {
      this.$emit('chosen', project.slug);
      this.slide();
    },
    slide() {
      const { currentProjectIndex } = this.$root;
      const inner = document.querySelector('.lines .inner');
      if (!inner) {
        return;
      }
      const p = Math.max(0, currentProjectIndex - 2);
      const nudge = window.outerWidth > 760 ? 24 : 18;
      inner.style.left = `${p * -nudge}px`;
    }
  },
  mounted() {
    this.slide();
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.lines {
  position: fixed;
  width: 30%;
  // margin: 10px;
  margin-top: 3vh;
  transition: background-color $open-fade-time;

  .inner {
    position: absolute;
    left: 0px;
    padding-left: $left-pad;
    transition: left 1s;
  }
}

$trans-time: 0.5s;
$line-height: 32px;
$line-width: $line-height / 8;
$margin-right: 20px;

.line-container {
  display: inline-block;
  transition: opacity 1s;
  &.hide {
    opacity: 0;
  }

  .line {
    background: #fff;
    height: $line-height;
    width: $line-width;
    margin-right: $margin-right;
    transform-origin: bottom right;
    transition: transform $trans-time,
                height $trans-time,
                margin-right $trans-time;
    transition-delay: 0.2s;
  }

  &.down, &:hover {
    .line {
      transform: rotate(90deg);
      margin-right: $margin-right * 2.7;
      height: $line-height * 0.7;
    }
  }

  &:hover {
    cursor: pointer;
    .text {
      opacity: 1;
    }
  }

  div.text {
    top: $line-height;
    line-height: 1em;
    max-width: $margin-right;
    white-space: pre;
    padding-left: $line-width;
    margin-top: 5px;
    opacity: 0;
    transition: opacity $trans-time * 2;
  }

}

@media only screen and (max-width: 768px) {
  .lines {
    height: 40px;
    width: 95%;
    margin-top: 0;
    padding-top: 20px;
    padding-left: 20px;
    .inner {
      padding-left: $photo-padding * 2;
    }
  }
  .line-container {

    div.text {
      opacity: 0;
    }
    &:hover {
      .text {
        opacity: 0;
      }

    }
  }

  $line-height: 24px;
  $line-width: $line-height / 8;
  $margin-right: 15px;

  .line-container {
    .line {
      height: $line-height;
      width: $line-width;
      margin-right: $margin-right;
    }

    &.down, &:hover {
      .line {
        margin-right: $margin-right * 2.7;
        height: $line-height * 0.7;
      }
    }

    div.text {
      top: $line-height;
      max-width: $margin-right;
      padding-left: $line-width;
    }
  }
}
</style>
