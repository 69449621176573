<template lang="html">
<div class="grid-item project-thumbnail"
      :class="{ 'force-half-width': small }"
      @click="moveToProject(project)">
  <div class="inner">
    <div class="img-container project-thumb-container"
        @mouseover="mouseOver = true"
        @mouseleave="mouseOver = false">
      <img :srcset="getThumbnailImageSrcSet(project)"
            :sizes="`(max-width: 600px) 100vw, (max-width: 960px) 66vw, 33vw`"
            @load="$emit('img-load')"
            alt="" />
      <div class="cover cover-background"
          :class="{ over: mouseOver }">
      </div>
      <div class="cover cover-overlay" :class="{ over: mouseOver }">
        <div class="text">
          <h2>{{ project.title }}</h2>
          <span>{{ project.category }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    project: Object,
    small: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      mouseOver: false,
    };
  },
  methods: {
    moveToProject(project) {
      this.$root.moveToProject(project.slug);
      this.$emit('moving', project);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.project-thumbnail {
  &:hover {
    cursor: pointer;
  }
}

.img-container {
  position: relative;
  // padding: 5px;
  color: #eee;

}

.img-container img {
  max-width: 100%;
  display: block;
}

.img-container .text h2 {
  font-size: 20px;
}

.cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  transition: opacity 1s;

  opacity: 0;
  &.over {
    opacity: 1;
  }

  .text {
    background: rgba(20, 20, 20, 0.8);
    padding: 10px;
    margin: 10px;
    display: inline-block;
  }

  .text h2 {
    // display: inline;
    margin: 0px;
    padding: 0px;
    font-size: 1.1rem;
    font-weight: lighter;
  }

  .text span {
    font-size: 1rem;
  }

  .text br {
    background: #0f0;
  }
}

.cover-background, {
  background: $black;

  &.over {
    opacity: 0.8;
  }
}

@media only screen and (max-width: 960px) {
  .cover-overlay {
    opacity: 1;
    .text {
      background: rgba(20, 20, 20, 0.2);
      color: $grey;
      padding: 5px;
      margin: 3px;
      line-height: 0.8rem;
      h2 {
        font-size: 0.7rem;


      }
      span {
        font-size: 0.7rem;
        margin: 0;
      }
    }
  }
}

</style>
