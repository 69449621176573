<template lang="html">
<two :masonry-selector="'.projects'">
  <template v-slot:left>
  </template>
  <template v-slot:right>
    <div class="projects">
      <div class="grid-sizer"></div>
      <project-thumbnail v-for="project in $root.projects" :key="project.slug"
                          :project="project"
                          @img-load="layoutImages">
      </project-thumbnail>
    </div>
  </template>
</two>
</template>

<script>
import Masonry from 'masonry-layout';
import Two from '@/components/Two.vue';
import ProjectThumbnail from '@/components/ProjectThumbnail.vue';

export default {
  components: {
    Two,
    ProjectThumbnail,
  },
  data() {
    return {
      mason: null,
    };
  },
  methods: {
    layoutImages() {
      const container = document.querySelector('.projects');

      this.mason = new Masonry(container, {
        itemSelector: '.grid-item',
        columnWidth: '.grid-sizer',
        percentPosition: true,
        transitionDuration: 0,
      });
    },
  }
};
</script>

<style lang="scss">
@import "@/styles/variables.scss";

.projects {
  margin: $photo-padding;
}


</style>
