<template lang="html">
<div class="columns">
  <left>
    <slot name="left"></slot>
  </left>
  <div class="column right tiling-container">
    <div :class="{ hide: $root.hidden || $root.hideNav }">
      <slot name="right"></slot>
    </div>
  </div>
</div>
</template>

<script>
import Vue from 'vue';
import Left from './Left.vue';

export default {
  components: {
    Left,
  },
  methods: {

  },
  beforeMount() {
    if (this.$root.background === '') {
      this.$root.background = this.$root.initialBackground;
    }
  },
  mounted() {
  },
};
</script>

<style lang="scss">
@import "@/styles/variables.scss";

div.column.right {
  position: absolute;
  width: 67%;
  min-height: 100vh;
  left: 33%;
  background: $grey;

  div {
    transition: opacity $open-fade-time;
  }
}

@media only screen and (max-width: 760px) {
  div.column.right {
    position: relative;
    left: 0;
    width: 100%;
    overflow-x: hidden;
    // left: 33%;
  }
}

</style>
